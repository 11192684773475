import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../config";
import { useParams } from "react-router-dom";

function HomePage() {
  const { eventId } = useParams();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await fetch(
          `${BACKEND_URL}/events/${eventId}/live_games`
        );
        if (response.ok) {
          const data = await response.json();
          setGames(data.games); // Zugriff auf das `games`-Array im Event-Datenformat
        } else {
          console.error("Fehler beim Abrufen der Spiele:", response.statusText);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Spiele:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [eventId]);

  const handleSingleGameClick = (gameId) => {
    navigate(`/game/${gameId}/gameViewer`);
  };

  const handleAllGamesClick = () => {
    navigate(`/liveGames/${eventId}`);
  };

  if (loading) return <div>Lädt Spiele...</div>;

  return (
    <div className="p-6 space-y-6">
      <h1 className="text-3xl font-semibold text-center">
        Willkommen im Darts Live-Viewer
      </h1>

      <div className="space-y-4">
        <h2 className="text-2xl font-semibold">Einzelnes Spiel verfolgen</h2>
        {games.length > 0 ? (
          <ul className="space-y-2">
            {games.map((game) => (
              <li key={game.id}>
                <button
                  onClick={() => handleSingleGameClick(game.id)}
                  className="w-full bg-blue-500 text-white py-2 rounded"
                >
                  {game.team1} vs {game.team2} - {game.round.description}{" "}
                  {game.round.group || ""}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>Derzeit sind keine Spiele verfügbar.</p>
        )}
      </div>

      <div className="space-y-4">
        <h2 className="text-2xl font-semibold">
          Alle aktuellen Spiele verfolgen
        </h2>
        <button
          onClick={handleAllGamesClick}
          className="w-full bg-green-500 text-white py-2 rounded"
        >
          Alle Spiele anzeigen
        </button>
      </div>
    </div>
  );
}

export default HomePage;
