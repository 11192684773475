import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../config";

function TournamentGroups() {
  const { eventId } = useParams();
  const [groupTables, setGroupTables] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState({
    group: null,
    teamIndex: null,
  });
  const [selectedGroup, setSelectedGroup] = useState("Alle Gruppen");

  useEffect(() => {
    fetchGroupTables();
  }, [eventId]); // Wird nur aufgerufen, wenn eventId sich ändert

  const fetchGroupTables = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/groupsRanking`
      );
      if (response.ok) {
        const data = await response.json();
        setGroupTables(data.group_tables || {});
      } else {
        console.error(
          "Fehler beim Abrufen der Gruppentabellen:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Gruppentabellen:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Lädt...</div>;

  if (!groupTables || Object.keys(groupTables).length === 0) {
    return <div>Keine Gruppentabellen verfügbar</div>;
  }

  const groupKeys = Object.keys(groupTables);

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Turnier Gruppentabellen</h2>

      {/* Dropdown zur Auswahl der Gruppen */}
      <select
        className="mb-4 p-2 border border-gray-300 rounded"
        value={selectedGroup}
        onChange={(e) => setSelectedGroup(e.target.value)}
      >
        <option value="Alle Gruppen">Alle Gruppen</option>
        {groupKeys.map((group) => (
          <option key={group} value={group}>
            {group}
          </option>
        ))}
      </select>

      {/* Anzeige der ausgewählten Gruppen */}
      {(selectedGroup === "Alle Gruppen" ? groupKeys : [selectedGroup]).map(
        (group) => (
          <div key={group} className="mb-6">
            <h3 className="text-xl font-semibold mb-2">{group}</h3>
            <div className="overflow-x-auto">
              {/* Tabelle für große Bildschirme */}
              <table className="min-w-full bg-white border border-gray-300 hidden md:table">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border">#</th>
                    <th className="px-4 py-2 border">Team</th>
                    <th className="px-4 py-2 border">Spiele gespielt</th>
                    <th className="px-4 py-2 border">Spiele gewonnen</th>
                    <th className="px-4 py-2 border">Spiele verloren</th>
                    <th className="px-4 py-2 border">Legs gewonnen</th>
                    <th className="px-4 py-2 border">Legs verloren</th>
                    <th className="px-4 py-2 border">Punkte</th>
                  </tr>
                </thead>
                <tbody>
                  {groupTables[group].map((teamData, index) => (
                    <tr key={index} className="text-center">
                      <td className="px-4 py-2 border">
                        {teamData.Platzierung}
                      </td>
                      <td className="px-4 py-2 border">{teamData.Team}</td>
                      <td className="px-4 py-2 border">
                        {teamData["Spiele gespielt"]}
                      </td>
                      <td className="px-4 py-2 border">
                        {teamData["Spiele gewonnen"]}
                      </td>
                      <td className="px-4 py-2 border">
                        {teamData["Spiele verloren"]}
                      </td>
                      <td className="px-4 py-2 border">
                        {teamData["Legs gewonnen"]}
                      </td>
                      <td className="px-4 py-2 border">
                        {teamData["Legs verloren"]}
                      </td>
                      <td className="px-4 py-2 border">{teamData.Punkte}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Kompakte Tabelle für mobile Ansicht */}
              <table className="min-w-full bg-white border border-gray-300 md:hidden">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border">#</th>
                    <th className="px-4 py-2 border">Team</th>
                    <th className="px-4 py-2 border">Punkte</th>
                  </tr>
                </thead>
                <tbody>
                  {groupTables[group].map((teamData, index) => (
                    <tr
                      key={index}
                      className="text-center cursor-pointer hover:bg-gray-200"
                      onClick={() =>
                        setSelectedTeam(
                          selectedTeam.group === group &&
                            selectedTeam.teamIndex === index
                            ? { group: null, teamIndex: null }
                            : { group, teamIndex: index }
                        )
                      }
                    >
                      <td className="px-4 py-2 border">
                        {teamData.Platzierung}
                      </td>
                      <td className="px-4 py-2 border">{teamData.Team}</td>
                      <td className="px-4 py-2 border">{teamData.Punkte}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Detailansicht für das ausgewählte Team */}
            {selectedTeam.group === group &&
              selectedTeam.teamIndex !== null && (
                <div className="bg-gray-100 p-4 border border-gray-300 rounded-md mt-4">
                  <h4 className="font-semibold mb-2">
                    Details für{" "}
                    {groupTables[group][selectedTeam.teamIndex].Team}
                  </h4>
                  <p>
                    <strong>#:</strong>{" "}
                    {groupTables[group][selectedTeam.teamIndex].Platzierung}
                  </p>
                  <p>
                    <strong>Spiele gespielt:</strong>{" "}
                    {
                      groupTables[group][selectedTeam.teamIndex][
                        "Spiele gespielt"
                      ]
                    }
                  </p>
                  <p>
                    <strong>Spiele gewonnen:</strong>{" "}
                    {
                      groupTables[group][selectedTeam.teamIndex][
                        "Spiele gewonnen"
                      ]
                    }
                  </p>
                  <p>
                    <strong>Spiele verloren:</strong>{" "}
                    {
                      groupTables[group][selectedTeam.teamIndex][
                        "Spiele verloren"
                      ]
                    }
                  </p>
                  <p>
                    <strong>Legs gewonnen:</strong>{" "}
                    {
                      groupTables[group][selectedTeam.teamIndex][
                        "Legs gewonnen"
                      ]
                    }
                  </p>
                  <p>
                    <strong>Legs verloren:</strong>{" "}
                    {
                      groupTables[group][selectedTeam.teamIndex][
                        "Legs verloren"
                      ]
                    }
                  </p>
                  <button
                    className="mt-2 text-blue-500 hover:underline"
                    onClick={() =>
                      setSelectedTeam({ group: null, teamIndex: null })
                    }
                  >
                    Details schließen
                  </button>
                </div>
              )}
          </div>
        )
      )}
    </div>
  );
}

export default TournamentGroups;
