import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function GameViewer({ gameId: propGameId }) {
  const { gameId: paramGameId } = useParams();
  const gameId = propGameId || paramGameId;

  const [gameData, setGameData] = useState(null);
  const [error, setError] = useState(null);
  const [legscoreChanged, setLegscoreChanged] = useState(false);

  const fetchInitialGameData = async () => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 5000);

    try {
      const response = await fetch(
        `http://localhost:8000/games/${gameId}/details`,
        {
          signal: controller.signal,
        }
      );
      if (!response.ok) {
        throw new Error("Fehler beim Abrufen der Spieldaten");
      }
      const data = await response.json();
      setGameData(data);
      setLegscoreChanged(false);
    } catch (error) {
      console.error("Fehler beim Abrufen der Spieldaten:", error);
      setError("Fehler beim Abrufen der Spieldaten.");
    } finally {
      clearTimeout(timeout);
    }
  };

  useEffect(() => {
    fetchInitialGameData();

    let socket;
    const connectWebSocket = () => {
      const wsUrl = `ws://localhost:8000/ws/game/${gameId}`;
      socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log("WebSocket connected for game:", gameId);
        setError(null);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        setGameData((prevData) => {
          if (
            prevData.legscore_team1 !== data.legscore_team1 ||
            prevData.legscore_team2 !== data.legscore_team2
          ) {
            setLegscoreChanged(true);
          }
          return {
            ...prevData,
            teams: {
              ...prevData.teams,
              [data.previous_team_id]: {
                ...prevData.teams[data.previous_team_id],
                score: data.current_team_score,
                current_player: {
                  id: data.next_player_id,
                  name: data.next_player_name,
                  check_mode: data.next_player_check_mode,
                  club_player: data.next_player_club_player,
                },
              },
            },
            current_team: data.current_team_id,
            legscore_team1: data.legscore_team1,
            legscore_team2: data.legscore_team2,
            leg_count: data.leg_count,
            winner_id: data.winner_id,
          };
        });
      };

      socket.onerror = (err) => {
        console.error("WebSocket error:", err);
        setError("Verbindungsfehler. Bitte später erneut versuchen.");
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed. Reconnecting...");
        setTimeout(connectWebSocket, 5000);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [gameId]);

  useEffect(() => {
    if (legscoreChanged) {
      fetchInitialGameData();
    }
  }, [legscoreChanged]);

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!gameData) {
    return <div className="text-center text-gray-500">Lädt Spieldaten...</div>;
  }

  return (
    <div className="p-6 w-full max-w-2xl bg-gray-900 text-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center mb-4">Darts Scoreboard</h2>
      <div className="grid grid-cols-2 gap-4">
        {["team1", "team2"].map((teamKey) => (
          <div
            key={teamKey}
            className={`p-6 rounded-lg border-4 ${
              gameData.current_team === teamKey
                ? "border-green-500"
                : "border-gray-700"
            }`}
          >
            <h3 className="text-2xl font-bold mb-2 text-center uppercase">
              {gameData.teams[teamKey].name}
            </h3>
            <div className="text-center text-4xl font-bold">
              {gameData.teams[teamKey].score}
            </div>
            <div className="flex justify-around mt-4 text-lg">
              <p>Legs Won: {gameData.teams[teamKey].legs_won}</p>
              <p>
                Current Player: {gameData.teams[teamKey].current_player.name}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-6">
        <p className="text-lg">Leg Count: {gameData.leg_count}</p>
        <p className="text-lg">Round: {gameData.round}</p>
        {gameData.winner_id && (
          <h3 className="text-2xl font-bold text-green-400 mt-4">
            Winner: {gameData.teams[gameData.current_team].name}
          </h3>
        )}
      </div>
    </div>
  );
}

export default GameViewer;
