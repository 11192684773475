import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../../config";
import GameViewer from "./GameViewer";

function LiveGames() {
  const { eventId } = useParams();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await fetch(
          `${BACKEND_URL}/events/${eventId}/live_games`
        );
        if (response.ok) {
          const data = await response.json();
          setGames(data.games);
        } else {
          console.error("Fehler beim Abrufen der Spiele:", response.statusText);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Spiele:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [eventId]);

  if (loading) return <div>Lädt Spiele...</div>;

  return (
    <div className="p-6 space-y-6">
      <h1 className="text-3xl font-semibold text-center">
        Alle aktuellen Spiele
      </h1>
      {games.length > 0 ? (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {games.map((game) => (
            <div key={game.id} className="border p-4 rounded-lg shadow-md">
              <GameViewer gameId={game.id} />
            </div>
          ))}
        </div>
      ) : (
        <p>Derzeit sind keine Spiele verfügbar.</p>
      )}
    </div>
  );
}

export default LiveGames;
