import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TournamentManagement from "./components/TournamentManagement";
import TeamEdit from "./components/TeamEdit";
import EventEdit from "./components/EventEdit";
import BoardEdit from "./components/BoardEdit";
import TournamentBracket from "./components/TournamentSchedule";
import GameCalculator from "./components/GameCalculator";
import TournamentGroups from "./components/TournamentGroups";
import FastGameForm from "./components/FastGameForm";
import GameViewer from "./components/frontend/GameViewer";
import HomePage from "./components/frontend/HomePage";
import LiveGames from "./components/frontend/LiveGames";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/tournamentManagement/:eventId/teamEdit"
            element={<TeamEdit />}
          />
          <Route
            path="/tournamentManagement/:eventId/eventEdit"
            element={<EventEdit />}
          />
          <Route
            path="/tournamentManagement/:eventId/boardEdit"
            element={<BoardEdit />}
          />
          <Route
            path="/tournamentManagement/:eventId/tournamentBracket"
            element={<TournamentBracket />}
          />
          <Route
            path="/tournamentManagement/games/:gameId/gameCalculator"
            element={<GameCalculator />}
          />
          <Route
            path="/tournamentManagement"
            element={<TournamentManagement />}
          />
          <Route
            path="/tournamentManagement/:eventId/tournamentGroups"
            element={<TournamentGroups />}
          />
          <Route path="/fastGame/new" element={<FastGameForm />} />
          <Route path="/game/:gameId/gameViewer" element={<GameViewer />} />
          <Route path="/home/:eventId" element={<HomePage />} />
          <Route path="/liveGames/:eventId" element={<LiveGames />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
