import {
  MdBackspace,
  MdCheck,
  MdUndo,
  MdSwapVert,
  MdGroup,
  MdViewModule,
  MdViewStream,
} from "react-icons/md"; // Importiere benötigte Icons

const CustomKeyboard = ({
  onNumberClick,
  onOkClick,
  onUndoClick,
  onDeleteClick, // Neue Prop für Löschen
  onPlayerSwitchClick,
  onTeamSwitchClick,
  onInputModeSwitch,
  useSingleInput,
}) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 p-4 shadow-l">
      <div className="grid grid-cols-4 gap-2">
        {/* Zahlen Buttons 1-3 */}
        {[1, 2, 3].map((num) => (
          <button
            key={num}
            onClick={() => onNumberClick(num.toString())}
            className="bg-gray-200 text-lg font-bold py-1 rounded flex justify-center items-center"
          >
            {num}
          </button>
        ))}
        {/* Team wechseln Button */}
        <button
          onClick={onTeamSwitchClick}
          className="bg-blue-500 text-white py-1 rounded flex justify-center items-center"
        >
          <MdSwapVert size={24} /> {/* Team wechseln Icon */}
        </button>

        {/* Zahlen Buttons 4-6 */}
        {[4, 5, 6].map((num) => (
          <button
            key={num}
            onClick={() => onNumberClick(num.toString())}
            className="bg-gray-200 text-lg font-bold py-1 rounded flex justify-center items-center"
          >
            {num}
          </button>
        ))}

        {/* Spieler wechseln Button */}
        <button
          onClick={onPlayerSwitchClick}
          className="bg-yellow-500 text-white py-1 rounded flex justify-center items-center"
        >
          <MdGroup size={24} /> {/* Spieler wechseln Icon */}
        </button>

        {/* Zahlen Buttons 7-9 */}
        {[7, 8, 9].map((num) => (
          <button
            key={num}
            onClick={() => onNumberClick(num.toString())}
            className="bg-gray-200 text-lg font-bold py-1 rounded flex justify-center items-center"
          >
            {num}
          </button>
        ))}
        {/* Löschen Button */}
        <button
          onClick={onDeleteClick}
          className="bg-red-500 text-white py-1 rounded flex justify-center items-center"
        >
          <MdBackspace size={24} /> {/* Backspace Icon */}
        </button>

        {/* Input Mode Switch Button */}
        <button
          onClick={onInputModeSwitch}
          className="bg-purple-500 text-white py-1 rounded flex justify-center items-center"
        >
          {useSingleInput ? (
            <MdViewStream size={24} />
          ) : (
            <MdViewModule size={24} />
          )}
        </button>

        {/* 0 Button */}
        <button
          onClick={() => onNumberClick("0")}
          className="bg-gray-200 text-lg font-bold py-1 rounded flex justify-center items-center"
        >
          0
        </button>

        {/* Undo Button */}
        <button
          onClick={onUndoClick}
          className="bg-orange-500 text-white py-1 rounded flex justify-center items-center"
        >
          <MdUndo size={24} /> {/* Undo Icon */}
        </button>

        {/* OK Button */}
        <button
          onClick={onOkClick}
          className="bg-green-500 text-white py-1 rounded flex justify-center items-center"
        >
          <MdCheck size={24} /> {/* Check Icon */}
        </button>
      </div>
    </div>
  );
};

export default CustomKeyboard;
