import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../config";

function BoardEdit() {
  const { eventId } = useParams();
  const [boards, setBoards] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [boardDescriptions, setBoardDescriptions] = useState({});

  useEffect(() => {
    fetchBoards();
    fetchRounds();
  }, [eventId]);

  const fetchBoards = async () => {
    const response = await fetch(`${BACKEND_URL}/events/${eventId}/boards`);
    if (response.ok) {
      const data = await response.json();

      // Initialisiere die Beschreibungen und weise zugeordnete Runden zu
      const descriptions = {};
      const boardsWithRounds = await Promise.all(
        data.map(async (board) => {
          descriptions[board.id] = board.description;
          const roundsResponse = await fetch(
            `${BACKEND_URL}/boards/${board.id}/rounds`
          );
          const assignedRounds = roundsResponse.ok
            ? await roundsResponse.json()
            : [];
          return { ...board, assignedRounds: assignedRounds.map((r) => r.id) };
        })
      );

      setBoards(boardsWithRounds);
      setBoardDescriptions(descriptions);
    } else {
      console.error("Fehler beim Laden der Boards:", response.statusText);
    }
  };

  const fetchRounds = async () => {
    const response = await fetch(`${BACKEND_URL}/events/${eventId}/rounds/`);
    if (response.ok) {
      const data = await response.json();
      setRounds(data);
    } else {
      console.error("Fehler beim Laden der Runden:", response.statusText);
    }
  };

  const handleDescriptionChange = (boardId, value) => {
    setBoardDescriptions((prevDescriptions) => ({
      ...prevDescriptions,
      [boardId]: value,
    }));
  };

  const saveDescription = async (boardId) => {
    const response = await fetch(
      `${BACKEND_URL}/boards/${boardId}/update_description`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          board_id: Number.parseInt(boardId),
          description: boardDescriptions[boardId],
        }),
      }
    );

    if (response.ok) {
      alert("Beschreibung erfolgreich aktualisiert!");
      fetchBoards();
    } else {
      console.error("Fehler beim Speichern der Beschreibung.");
    }
  };

  const handleRoundCheckboxChange = (boardId, roundId) => {
    setBoards((prevBoards) =>
      prevBoards.map((board) =>
        board.id === boardId
          ? {
              ...board,
              assignedRounds: board.assignedRounds.includes(roundId)
                ? board.assignedRounds.filter((id) => id !== roundId)
                : [...board.assignedRounds, roundId],
            }
          : board
      )
    );
  };

  const saveBoardRounds = async (boardId) => {
    const board = boards.find((b) => b.id === boardId);
    const response = await fetch(
      `${BACKEND_URL}/boards/${boardId}/assign_rounds`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ round_ids: board.assignedRounds }),
      }
    );

    if (response.ok) {
      alert("Runden erfolgreich zugewiesen!");
    } else {
      console.error("Fehler beim Speichern der zugewiesenen Runden.");
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Board-Bearbeitung</h2>
      <div className="mb-6">
        <h3 className="text-lg font-medium mb-2">Boards</h3>
        <ul className="space-y-4">
          {boards.map((board) => (
            <li key={board.id} className="p-4 bg-gray-200 rounded-lg space-y-4">
              <div className="flex items-center">
                <input
                  type="text"
                  value={boardDescriptions[board.id]}
                  onChange={(e) =>
                    handleDescriptionChange(board.id, e.target.value)
                  }
                />
              </div>
              <div className="space-y-2">
                <h4 className="text-lg font-medium">Runden:</h4>
                <ul className="space-y-2">
                  {rounds.map((round) => (
                    <li key={round.id} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={board.assignedRounds.includes(round.id)}
                        onChange={() =>
                          handleRoundCheckboxChange(board.id, round.id)
                        }
                        className="mr-2"
                      />
                      <label>{round.description}</label>
                    </li>
                  ))}
                </ul>
              </div>
              <button
                onClick={() => {
                  saveBoardRounds(board.id);
                  saveDescription(board.id);
                }}
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                Runden speichern
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default BoardEdit;
