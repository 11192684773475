import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../config";

function TeamEdit() {
  const { eventId } = useParams();
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [newPlayer, setNewPlayer] = useState({
    name: "",
    club_player: false,
  });
  const [newTeamName, setNewTeamName] = useState("");
  const [eventDetails, setEventDetails] = useState(null);
  const [teamComplete, setTeamComplete] = useState(false);

  useEffect(() => {
    if (eventId) {
      fetchEventDetails();
      fetchTeamsWithPlayers();
      fetchGroups();
    }
  }, [eventId]);

  useEffect(() => {
    if (eventDetails && teams.length > 0) {
      checkTeamCompletion(teams);
    }
  }, [teams, eventDetails]);

  const fetchEventDetails = async () => {
    const response = await fetch(`${BACKEND_URL}/events/${eventId}`);
    const data = await response.json();
    setEventDetails(data);
  };

  const fetchTeamsWithPlayers = async () => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/events/${eventId}/teams_with_players`
      );
      const data = await response.json();

      // Setze die Teams und überprüfe die Team-Vervollständigung
      setTeams(data);
      checkTeamCompletion(data);
    } catch (error) {
      console.error("Fehler beim Abrufen der Teams mit Spielern:", error);
    }
  };

  const fetchGroups = async () => {
    const response = await fetch(`${BACKEND_URL}/events/${eventId}/groups`);
    const data = await response.json();
    setGroups(data);
  };

  const checkTeamCompletion = (teamsData) => {
    if (eventDetails) {
      const totalTeamsRequired =
        (eventDetails.group_count || 0) * (eventDetails.group_team_count || 0);

      const allTeamsAdded = teamsData.length === totalTeamsRequired;
      const allPlayersComplete = teamsData.every(
        (team) =>
          (team.players?.length || 0) === (eventDetails.team_player_count || 0)
      );

      const allTeamsAssignedToGroups = teamsData.every(
        (team) => team.group_id !== null && team.group_id !== undefined
      );

      const groupsComplete = groups.every((group) => {
        const teamsInGroup = teamsData.filter(
          (team) => String(team.group_id) === String(group.id)
        ).length;

        return teamsInGroup === eventDetails.group_team_count;
      });

      const isTeamComplete =
        allTeamsAdded &&
        allPlayersComplete &&
        allTeamsAssignedToGroups &&
        groupsComplete;
      setTeamComplete(isTeamComplete);
    }
  };

  const removeGroupFromTeam = async (teamId) => {
    const response = await fetch(
      `${BACKEND_URL}/teams/${teamId}/remove_group`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      alert("Gruppe erfolgreich entfernt!");
      fetchTeamsWithPlayers();
    } else {
      console.error("Fehler beim Entfernen der Gruppe.");
    }
  };

  const randomAssignTeamsToGroups = async () => {
    const totalGroups = groups.length;
    const teamsPerGroup = eventDetails.group_team_count;

    // Teams und Gruppen zufällig mischen
    const shuffledTeams = [...teams].sort(() => Math.random() - 0.5);
    const shuffledGroups = [...groups].sort(() => Math.random() - 0.5);

    let groupAssignments = {};

    for (const group of shuffledGroups) {
      groupAssignments[group.id] = 0; // Initialisiere die Zählung für jedes Team in der Gruppe
    }

    for (const team of shuffledTeams) {
      // Finde eine zufällige Gruppe, die noch Platz hat
      let assignedGroup = null;

      while (!assignedGroup) {
        const randomGroup =
          shuffledGroups[Math.floor(Math.random() * totalGroups)];

        if (groupAssignments[randomGroup.id] < teamsPerGroup) {
          assignedGroup = randomGroup;
          groupAssignments[randomGroup.id]++;
        }
      }

      // Weise das Team der ausgewählten Gruppe zu
      await assignTeamToGroup(team.id, assignedGroup.id);
    }

    // Aktualisiere die Teams und Gruppen
    fetchTeamsWithPlayers();
  };

  const removeAllGroups = async (event_id) => {
    // Bestätigungsdialog anzeigen
    const confirmed = window.confirm(
      "Bist du sicher, dass du alle Gruppenzugehörigkeiten entfernen möchtest? Dieser Vorgang kann nicht rückgängig gemacht werden."
    );

    // Falls der Nutzer bestätigt, wird der Vorgang fortgesetzt
    if (confirmed) {
      const response = await fetch(
        `${BACKEND_URL}/events/${event_id}/remove_all_groups`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        alert("Gruppen erfolgreich entfernt!");
        fetchTeamsWithPlayers();
      } else {
        console.error("Fehler beim Entfernen der Gruppen.");
      }
    } else {
      console.log("Löschen der Gruppenzugehörigkeiten abgebrochen.");
    }
  };

  const assignTeamToGroup = async (teamId, groupId) => {
    if (groupId === "") {
      // Wenn "Gruppe wählen" ausgewählt wurde, rufe removeGroupFromTeam auf
      removeGroupFromTeam(teamId);
      return;
    }

    const response = await fetch(
      `${BACKEND_URL}/teams/${teamId}/assign_group/${groupId}`,
      {
        method: "POST",
      }
    );

    if (response.ok) {
      fetchTeamsWithPlayers();
    } else {
      const error = await response.json();
      alert(`Fehler: ${error.detail}`);
    }
  };

  const addPlayer = async (teamId) => {
    const clubPlayersCount = selectedTeam.players.filter(
      (player) => player.club_player === true
    ).length;

    if (
      newPlayer.club_player &&
      clubPlayersCount >= eventDetails.max_club_players
    ) {
      alert(
        `Es dürfen nur maximal ${eventDetails.max_club_players} Vereinsspieler im Team sein.`
      );
      return;
    }

    const response = await fetch(
      `${BACKEND_URL}/teams/${teamId}/create_player/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: newPlayer.name,
          club_player: newPlayer.club_player,
        }),
      }
    );

    if (response.ok) {
      await fetchTeamsWithPlayers();
      const updatedPlayers = await fetchPlayers(teamId);
      setSelectedTeam((prevTeam) => ({
        ...prevTeam,
        players: updatedPlayers,
      }));
      setNewPlayer({ name: "", club_player: false });
    }
  };

  function GroupTeamOverview({ groups, teams, eventDetails }) {
    const getTeamsByGroup = (groupId) => {
      const a = teams.filter(
        (team) => String(team.group_id) === String(groupId)
      );
      return a;
    };

    return (
      <div className="p-4">
        <h2 className="text-2xl font-semibold mb-4">Gruppenübersicht</h2>
        <div className="space-y-4">
          {groups.map((group) => (
            <div
              key={group.id}
              className="border rounded-lg p-4 bg-gray-100 shadow-md"
            >
              <h3 className="text-xl font-semibold mb-2">{`Gruppe: ${group.description}`}</h3>
              <ul className="list-disc list-inside space-y-2">
                {getTeamsByGroup(group.id).map((team) => (
                  <li
                    key={team.id}
                    className="flex items-center justify-between"
                  >
                    <span className="text-lg font-medium">{team.name}</span>
                    <span
                      className={`text-sm font-medium ${
                        team.players?.length === eventDetails?.team_player_count
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {`Spieler: ${team.players?.length || 0}/${
                        eventDetails?.team_player_count || 0
                      }`}
                    </span>
                  </li>
                ))}
                {getTeamsByGroup(group.id).length === 0 && (
                  <li className="text-gray-500">Keine Teams zugewiesen</li>
                )}
              </ul>
              <div className="text-sm text-gray-600 mt-2">
                {`Slots: ${getTeamsByGroup(group.id).length} / ${
                  eventDetails.group_team_count || 0
                }`}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const deletePlayer = async (playerId, teamId) => {
    const response = await fetch(`${BACKEND_URL}/players/${playerId}`, {
      method: "DELETE",
    });

    if (response.ok) {
      await fetchTeamsWithPlayers();
      const updatedPlayers = await fetchPlayers(teamId);
      setSelectedTeam((prevTeam) => ({
        ...prevTeam,
        players: updatedPlayers,
      }));
    }
  };

  const deleteTeam = async (teamId) => {
    const response = await fetch(`${BACKEND_URL}/teams/${teamId}`, {
      method: "DELETE",
    });

    if (response.ok) {
      fetchTeamsWithPlayers();
    }
  };

  const fetchPlayers = async (teamId) => {
    const response = await fetch(`${BACKEND_URL}/teams/${teamId}/players`);
    const playersData = await response.json();
    setTeams((prevTeams) =>
      prevTeams.map((team) =>
        team.id === teamId ? { ...team, players: playersData } : team
      )
    );

    return playersData;
  };

  const createTeam = async () => {
    if (teams.length > 0) {
      const duplicateTeam = teams.find(
        (team) => team.name.toLowerCase() === newTeamName.toLowerCase()
      );
      if (duplicateTeam) {
        alert(
          "Teamname existiert bereits! Bitte wählen Sie einen anderen Namen."
        );
        return;
      }
    }

    const totalTeamsRequired =
      (eventDetails.group_count || 0) * (eventDetails.group_team_count || 0);

    if (teams.length >= totalTeamsRequired) {
      alert("Die maximale Anzahl an Teams wurde bereits erreicht.");
      return;
    }

    const response = await fetch(
      `${BACKEND_URL}/events/${eventId}/create_team/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: newTeamName }),
      }
    );

    if (response.ok) {
      fetchTeamsWithPlayers();
      setNewTeamName("");
    }
  };

  const handleSelectTeam = (team) => {
    setSelectedTeam(team);
    fetchPlayers(team.id);
  };

  const areAllGroupsEmpty = () => {
    return teams.every(
      (team) => team.group_id === null || team.group_id === undefined
    );
  };

  const releaseTeams = async () => {
    if (!teamComplete) {
      alert(
        "Stellen Sie sicher, dass alle Teams und Spieler vollständig sind!"
      );
      return;
    }

    const response = await fetch(
      `${BACKEND_URL}/events/${eventId}/release_teams`,
      {
        method: "PUT",
      }
    );

    if (response.ok) {
      alert("Teams erfolgreich freigegeben!");
      fetchEventDetails(); // Aktuellen Status aktualisieren
    } else {
      console.error("Fehler beim Freigeben der Teams");
    }
  };

  return eventDetails ? (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Teamverwaltung</h2>
      <div className="flex flex-row space-x-5">
        <p className="mb-4 text-lg font-medium">
          Teams: {teams.length} /{" "}
          {(eventDetails?.group_count || 0) *
            (eventDetails?.group_team_count || 0)}
        </p>
        <p className="mb-4 text-lg font-medium">
          Spieler pro Team: {eventDetails.team_player_count}
        </p>
        <p className="mb-4 text-lg font-medium">
          Teams pro Gruppe: {eventDetails.group_team_count}
        </p>
        <p className="mb-4 text-lg font-medium">
          Anzahl Gruppen: {eventDetails.group_count}
        </p>
        <p className="mb-4 text-lg font-medium">
          Vereinsspieler:
          {eventDetails.allow_club_players
            ? eventDetails.max_club_players
            : "Nein"}
        </p>
      </div>

      {!selectedTeam && (
        <div className="mb-6">
          {teams.length > 0 && (
            <>
              <h3 className="text-xl font-semibold mb-4">Teams</h3>
              <ul className="space-y-4">
                {teams.map((team) => (
                  <li
                    key={`team-${team.id}`}
                    className="flex items-center justify-between p-4 bg-gray-100 rounded-lg"
                  >
                    <span className="text-lg font-medium">{team.name}</span>
                    <div className="flex items-center space-x-4">
                      <span>
                        Gruppe:
                        <select
                          value={team.group_id || ""}
                          onChange={(e) =>
                            assignTeamToGroup(team.id, e.target.value)
                          }
                          disabled={eventDetails.teams_completed}
                          className="border border-gray-300 p-1 ml-2"
                        >
                          <option value="">Gruppe wählen</option>
                          {groups.map((group) => (
                            <option key={group.id} value={group.id}>
                              {group.description}
                            </option>
                          ))}
                        </select>
                      </span>
                      <span
                        className={`text-sm font-medium ${
                          team.players?.length ===
                          eventDetails?.team_player_count
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {`Spieler: ${team.players?.length || 0}/${
                          eventDetails?.team_player_count || 0
                        }`}
                      </span>
                      <button
                        onClick={() => handleSelectTeam(team)}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                      >
                        Spieler verwalten
                      </button>
                      {!eventDetails.teams_completed && (
                        <button
                          onClick={() => deleteTeam(team.id)}
                          className="bg-red-500 text-white px-4 py-2 rounded"
                        >
                          Team löschen
                        </button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}

          {/* Neues Team erstellen */}

          {(teams?.length || 0) <
            (eventDetails?.group_count || 0) *
              (eventDetails?.group_team_count || 0) && (
            <div className="mt-6">
              <h4 className="text-lg font-medium mb-2">Neues Team erstellen</h4>
              <input
                type="text"
                placeholder="Teamname"
                value={newTeamName}
                onChange={(e) => setNewTeamName(e.target.value)}
                className="border border-gray-300 p-2 rounded mb-2 w-full"
              />
              <button
                onClick={createTeam}
                className="bg-green-500 text-white px-4 py-2 rounded w-full"
              >
                Team erstellen
              </button>
            </div>
          )}
        </div>
      )}
      {/* Spieler Ansicht */}
      {selectedTeam && (
        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-4">{`Spieler im Team ${selectedTeam.name}`}</h3>
          <ul className="space-y-2">
            {teams
              .find((team) => team.id === selectedTeam.id)
              ?.players.map((player) => (
                <li
                  key={`player-${player.id}`}
                  className="flex items-center justify-between p-2 bg-gray-200 rounded-lg"
                >
                  <div> {player.name}</div>
                  <div>
                    {"Vereinsspieler: " + (player.club_player ? "Ja" : "Nein")}
                  </div>

                  {!eventDetails.teams_completed && (
                    <button
                      onClick={() => deletePlayer(player.id, selectedTeam.id)}
                      className="text-red-500 hover:underline"
                    >
                      Entfernen
                    </button>
                  )}
                </li>
              ))}
          </ul>

          {/* Spieler hinzufügen */}
          {teams.find((team) => team.id === selectedTeam.id)?.players.length <
            (eventDetails?.team_player_count || 0) && (
            <div className="mt-4">
              <h4 className="text-lg font-medium mb-2">
                Neuen Spieler hinzufügen
              </h4>
              <div className="flex flex-col bg-white border rounded-lg mb-4">
                <input
                  type="text"
                  placeholder="Spielername"
                  value={newPlayer.name}
                  onChange={(e) =>
                    setNewPlayer((prev) => ({ ...prev, name: e.target.value }))
                  }
                  className="border border-gray-300 p-2 rounded mb-4 focus:outline-none focus:border-blue-500 transition duration-150"
                />
                {eventDetails.allow_club_players && (
                  <label className="flex items-center space-x-2 text-gray-700">
                    <input
                      type="checkbox"
                      checked={newPlayer.club_player}
                      onChange={(e) =>
                        setNewPlayer((prev) => ({
                          ...prev,
                          club_player: e.target.checked,
                        }))
                      }
                      className="form-checkbox h-5 w-5 text-blue-600 transition duration-150"
                    />
                    <span>Vereinsspieler</span>
                  </label>
                )}
              </div>

              <button
                onClick={() => addPlayer(selectedTeam.id)}
                className="bg-green-500 text-white px-4 py-2 rounded w-full"
              >
                Spieler hinzufügen
              </button>
            </div>
          )}
          <button
            onClick={() => {
              setSelectedTeam(null);
            }}
            className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
          >
            Zurück zu den Teams
          </button>
        </div>
      )}

      {!selectedTeam & (teams.length > 0) && (
        <>
          <GroupTeamOverview
            groups={groups}
            teams={teams}
            eventDetails={eventDetails}
          />
          <button
            onClick={() => {
              removeAllGroups(eventId);
            }}
            className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
          >
            Alle Gruppen löschen
          </button>
          {areAllGroupsEmpty() && (
            <button
              onClick={() => {
                randomAssignTeamsToGroups();
              }}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
            >
              Random
            </button>
          )}
        </>
      )}
      {teamComplete && !eventDetails.teams_completed && (
        <div>
          <button
            onClick={releaseTeams}
            className="mt-6 bg-green-500 text-white px-4 py-2 rounded"
            disabled={!teamComplete}
          >
            Teams freigeben
          </button>
        </div>
      )}

      {eventDetails.teams_completed && (
        <>
          <h2 className="text-2xl font-semibold mb-4 text-red-600 text-center">
            Teams und Gruppen freigeben
          </h2>
        </>
      )}
    </div>
  ) : (
    <div>Loading...</div>
  );
}

export default TeamEdit;
